.admin-container {
  padding: 16px 32px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.admin-container header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.admin-container header h1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.admin-container nav ul li {
  display: inline-block;
  margin-right: 16px;
}

.admin-container a {
  color: #000;
  /* font-size: 1.2rem; */
  /* font-weight: 500; */
  /* text-decoration: none; */
}

blockquote {
  background-color: #eee;
  padding: 8px;
  margin: 8px 0;
  border-left: 8px solid #ddd;
}

td {
  vertical-align: baseline;
}

input {
  border: 0;
  padding: 16px;
  font-size: 16px;
  border-bottom: 1px solid #aaa;
}
