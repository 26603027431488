.intent-list-section {
    margin: 0 50px;
}

.intent-list-list-container {
    margin-top: 20px;
}

.add-new-intent-btn-container {
    display: grid;
    place-items: center;
    margin: 30px
}

.intent-list-desc {
    white-space: pre-wrap;
}