.intent-header {
    display: grid;
    grid-template-columns: 1fr  auto auto auto auto;
    width: 100%;
    grid-gap: 10px;
}

.intent-container.expired {
    opacity: 0.4
}

.intent-header-expiry {
    color: red
}

.intent-accordion-body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

.intent-accordion-summary {
    width: 100%;
    padding: 0px 20px !important;
}