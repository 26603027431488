.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
}

.mahirContainer {
  height: 100px;
  position: relative;
  left:0;
  
  display: flex;
  justify-content: center;
  align-items: center;
}