.intent-delete-warning-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

#intent-delete-warning-cancel {
    background: #64748B;
    color: #fff
}