.game {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background: white;
  opacity: .92;
}

.panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
}

.start-btn {
  font-size: 16px;
  font-weight: 300;
  border:2px solid #cccccc;
  padding: 8px 12px;
  border-radius: 3px;
  margin-top: 50px;
  background: white;
  text-align: center;
  font-weight: bold;
  color: #767676;
  cursor: pointer;
  transition: all .3s;
}

.start-btn:hover {
  background: #cccccc;
}


.box {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
}

.block {
  flex: 1;
  border-right: 5px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 500;
  color: #767676;
}

.block:last-child {
  border-right: none;
}

.row {
  display: flex;
  flex: 1;
  border-bottom: 5px solid lightgray;
}

.row:last-child {
  border-bottom: none;
}

.actions {
  display: flex;
}

.actions > div {
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;
}