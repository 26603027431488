* {
  box-sizing: border-box;
}

.Messenger {
  max-width: 600px;
  width: 100%;
  margin: auto;
  background-color: #eee;

  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  z-index: 1;

  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  font-size: 14px;
}

.messageListOuter {
  flex-grow: 1;
  /* overflow: hidden;
  display: flex;
  flex-direction: column; */
  position: relative;
}

.messageList {
  position: absolute;
  padding: 8px 0;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  
}

.messageList pre, .messageList code {
  font-family: monospace;
  line-height: 1.5;
  font-size: 14px;
  /* background-color: #000; */
  /* color: #fff; */
  /* padding: 8px; */
  border-radius: 4px;
  
  white-space: pre-wrap;
  overflow-x: scroll;

}

.messageList pre {
  padding: 8px;

}

.messageList a {
  color: #fff;
  text-decoration: underline;
}

.messageListItem {
  display: flex;
  align-items: flex-end;
}

.messageListItemContent {
  background-color: rgb(94, 94, 94);
  color: white;
  padding: 8px;
  border-radius: 10px;
  margin: 8px 16px;
}

.messageListItem p {
  margin: 0;
}

.messageListItem.sent {
  justify-content: flex-end;
}
.messageListItem.sent .messageListItemContent {
  background-color: #1e61ac;
  border-radius: 10px 10px 0 10px;
}

.messageListItem.received .messageListItemContent {
  background-color: rgb(94, 94, 94);
  border-radius: 10px 10px 10px 0;
}
.messageListItem.received.writing .messageListItemContent {
  background-color: rgba(94, 94, 94, 0.521);
}

.messageListItemContentText > p {
  word-break: break-word;
}

.messageInput {
  border-top: 1px solid #ccc;
  flex: 0;
  background-color: #eee;
  padding: 16px;

  position: relative;
}

.messageInputText input {
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 16px;
}

.messengerGame {
  padding: 10px;
  display: grid;
  place-items: center;
}

.verified {
  width: 20px;
  height: 20px;
  margin-bottom: -8px;
  margin-left: -23px;
}

.warning {
  width: 15px;
  height: 15px;
  margin-bottom: -7px;
  margin-left: -20px;
  margin-right: 3px;
}

.popup {
  position: absolute;
  margin-left: 1rem;
  margin-bottom: -0.9rem;
  font-size: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  background-color: rgba(9, 7, 7, 0.9);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.verified:hover + .popup {
  visibility: visible;
  opacity: 1;
}

.warning:hover + .popup {
  visibility: visible;
  opacity: 1;
}
