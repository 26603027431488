:root {
    --controls: hsl(38, 96%, calc((55 + var(--lightness, 0)) * 1%));
    --controls-secondary: hsl(55, 100%, 50%);
    --controls-color: hsl(0, 0%, 100%);
    --sky: hsl(204, 80%, 80%);
    --grass: hsl(98, 40%, 50%);
    --dirt: hsl(35, 40%, 20%);
}

* {
    box-sizing: border-box;
}

.whack_a_mole_container {
    display: flex;
    max-height: 60vh;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    font-family: 'Fredoka One', cursive;
    background: var(--sky);
    position: relative;
}

.moles {
    display: inline-grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, auto);
    grid-gap: 0 2vmin;
    cursor: none;
}

.moles > *:nth-of-type(4),
.moles > *:nth-of-type(5) {
    transform: translate(50%, -25%);
}

main {
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
    background: linear-gradient(var(--sky) 0 44%, var(--grass) 44%);
}

button {
    --controls: hsl(38, 96%, calc((55 + var(--lightness, 0)) * 1%));
    background: var(--controls);
    color: var(--controls-color);
    padding: 1rem 2rem;
    font-family: 'Fredoka One', cursive;
    font-weight: bold;
    font-size: 1.75rem;
    border-radius: 1rem;
    border: 4px var(--controls-color) solid;
    white-space: nowrap;
    cursor: pointer;
}

button:hover {
    --lightness: 5;
}
button:active {
    --lightness: -15;
}

.celebration {
    font-size: 4rem;
    line-height: 1;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
}

.word {
    display: inline-block;
    white-space: nowrap;
}

.celebration .char {
    display: inline-block;
    color: hsl(calc((360 / var(--char-total)) * var(--char-index)), 70%, 65%);
    animation: jump 0.35s calc(var(--char-index, 0) * -1s) infinite;
}

.countdown-number {
    font-size: 10rem;
    color: var(--dirt);
    -webkit-text-stroke: 0.25rem var(--controls-color);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 12;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
    display: 'none';
}

@keyframes jump {
    50% {
        transform: translate(0, -25%);
    }
}

.icon-button {
    height: 48px;
    width: 48px;
    outline: transparent;
    background: none;
    border: 0;
    display: grid;
    place-items: center;
    padding: 0;
    margin: 0;
}

.mute-button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.mute-button:hover ~ .mallet,
.end-button:hover ~ .mallet {
    display: none;
}

.end-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.game-info {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    align-items: center;
    grid-gap: 0.5rem 1rem;
    z-index: 2;
    background: var(--controls-color);
    border: 4px solid var(--controls);
    border-radius: 1rem;
    padding: 1rem;
    width: 190px;
    pointer-events: none;
}

.info-screen {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.results {
    background: var(--controls-color);
    padding: 2rem;
    border: 4px solid var(--controls);
    border-radius: 1rem;
}

.info-screen > * + * {
    margin-top: 1rem;
}

.icon {
    fill: hsl(35, 50%, 28%);
    stroke-width: 20px;
    overflow: visible;
    height: 24px;
    width: 24px;
}

@media(min-width: 768px) {
    .end-button {
        top: 1rem;
        right: 1rem;
    }
    .mute-button {
        bottom: 1rem;
        right: 1rem;
    }
    .icon {
        height: 48px;
        width: 48px;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.info__text {
    font-size: clamp(1rem, 5vmin, 2rem);
    line-height: 1;
    color: var(--dirt);
    margin: 0;
}

.boring-text {
    font-size: 2rem;
    text-align: center;
}


.title {
    -webkit-text-stroke: 0.1vmin var(--controls-color);
    font-size: 6rem;
    font-weight: bold;
    color: transparent;
    background: linear-gradient(40deg, var(--controls), var(--controls-secondary));
    -webkit-background-clip: text;
    background-clip: text;
    text-align: center;
    display: inline-block;
    line-height: 0.75;
    margin: 0 0 4rem 0;
    padding: 0;
    transform: rotate(-15deg);
}

.title span {
    display: block;
}
.title span:nth-of-type(2) {
    transform: translate(0, -10%) rotate(15deg);
    color: var(--controls);
}
.hole {
    fill: hsl(0, 0%, 12%);
}

.hole__lip {
    fill: hsl(38, 20%, 50%);
}

.mole__feature {
    fill: hsl(0, 0%, 10%);
}

.mole__eyes--crossed {
    display: none;
}

.mole__mole {
    display: none;
}

.specs__lens {
    fill: hsla(198, 80%, calc((80 - (var(--shades, 0) * 75)) * 1%), calc(0.5 + (var(--shades, 0) * 0.5)));
    stroke: hsl(var(--accent), 25%, calc((30 - (var(--shades, 0) * 30)) * 1%));
}

.cap__accent {
    fill: hsl(var(--accent, 10), 80%, 50%);
}

.cap__body {
    fill: hsl(0, 0%, 5%);
}

.specs__glare {
    fill: hsla(0, 0%, 100%, calc(0.5 + (var(--shades, 0) * 0.25)));
}

.specs__bridge {
    stroke: hsl(var(--accent), 25%, calc((30 - (var(--shades, 0) * 30)) * 1%));
}

.mole__hole {
    width: 20vmin;
    height: 20vmin;
    position: relative;
    cursor: none;
}

.mole__hole * {
    cursor: none;
}

.mole__body {
    fill: hsl(var(--hue), calc((10 + (var(--golden, 0) * 40)) * 1%), calc(var(--lightness, 65) * 1%));
}

.mole__white {
    fill: hsl(40, 80%, calc((98 - (var(--golden, 0) * 15)) * 1%));
}

.mole__whiskers {
    stroke: hsl(40, calc((0 + (var(--golden, 0) * 35)) * 1%), calc((5 + (var(--golden, 0) * 40)) * 1%));
}

.mole__shadow {
    fill: hsl(var(--hue), 16%, 43%);
}

.mole__nose {
    fill: hsl(calc(10 + (var(--golden, 0) * 30)), 90%, calc((88 - (var(--golden, 0) * 35)) * 1%));
}

.mole {
    position: absolute;
    height: 100%;
    width: 100%;
}

.mole__whack {
    height: 100%;
    width: 100%;
    border: 0;
    opacity: 0;
    transform: translate(0, 0%);
    position: absolute;
    top: 0;
    left: 0;
}

.mole__points-holder {
    position: absolute;
    transform: rotate(calc(var(--angle, 0) * 1deg));
    transform-origin: 50% 200%;
    pointer-events: none;
    position: absolute;
    z-index: 10;
}

.mole__points {
    font-size: clamp(2rem, 8vmin, 18rem);
    pointer-events: none;
    font-weight: bold;
    color: hsl(var(--accent, 0), 90%, 75%);
    margin: 0;
    transform: translate(-50%, -200%);
    -webkit-text-stroke: 0.1vmin hsl(var(--accent), 50%, 35%);
}

.mallet {
    height: 0px;
    width: 0px;
    background: green;
    pointer-events: none;
    position: fixed;
    top: calc(var(--y) * 1px);
    left: calc(var(--x) * 1px);
    z-index: 10;
    transform: translate(-50%, -50%);
    display: none;
}

.mallet img {
    position: absolute;
    bottom: 0;
    height: 18vmin;
    transform-origin: 75% 85%;
    pointer-events: none;
}

@media (hover: none) {
    .mallet img {
        display: none;
    }
}

.hiscore {
    text-transform: uppercase;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
}