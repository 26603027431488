.edit-intent-title {
    display: grid;
    grid-template-columns: auto 200px 2fr 1fr;
    grid-gap: 30px;
    margin-bottom: 30px;
    place-items: center;
}

.edit-intent-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    max-height: 75vh;
    overflow-y: auto;
}

.inline-utter-resp {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 5px 0px;
    grid-gap: 5px;
}

.add-new-container {
    display: grid;
    place-items: center;
    padding: 10px;
}

.edit-intent-error {
    color: red;
    font-size: 1.25rem
}

.edit-intent-footer {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 20px
}

#intent-form-close {
    background: #64748B;
    color: #fff
}

.edit-intent-expiry {
    justify-self: end;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
}

.edit-intent-expiry-help {
    width: 64px !important;
    display: grid;
    place-items: center;
    cursor: help;
}

.edit-intent-expiry-help-msg {
    max-width: 200px;
    word-break: break-word;
    padding: 10px;
}

.edit-intent-expiry-help-msg > *:first-child {
    font-weight: bold;
}

.edit-intent-expiry-help-msg > * {
    padding: 5px 0px
}
