.add-new-category-btn-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    margin: 30px
}

.admin-users-container {
    margin-top: 20px;
    width: 100%;
}