.mahir-container {
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-name: "slideIn";
    transition: 1s;
    transition-delay: 1s;
    height: 100%;
    width: 72px;
    z-index: 0;
    user-select: none;
    position: relative;
    /* overflow: hidden; */
    top: 20px
}

.mahir-container.do-not-like {
    transform: translateY(200px) rotate(-45deg);
}

.mahir-img {
    position: absolute;
    bottom: 0%;
    left: 0px;
    height: 100px;
    z-index: 0;
    cursor: pointer;
}

.eye {
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background: #fff;
    bottom: 52px;
    left: 20px;
    cursor: pointer;
}
.eye:after { /*pupil*/
    position: absolute;
    bottom: 8px;
    right: 5px;
    width: 3px;
    height: 3px;
    background: #000;
    border-radius: 50%;
    content: " ";
}

.eye:last-of-type {
    margin-left: 15px;
}