.login-container {
    display: grid;
    place-items: center;
    height: 80vh;
    width: 100vw
}

.login-controls {
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
    grid-gap: 20px;
    padding: 40px;
    border: 1px solid lightgray;
}

.login-error {
    color: red;
}