.email-form-container {
    margin: 60px auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 40px;
    max-width: 1000px;
}


.email-form-container > *:nth-child(odd) {
    justify-self: end;
    margin: 60px 0
}

.email-form-item-container {
    display: grid;
    place-items: center;
}
